import { useAuthStore } from "@/store/auth";

export default defineNuxtRouteMiddleware((to, from) => {
  const auth = useAuthStore();
  const nuxtApp = useNuxtApp();

  if (auth.loggedIn === true) {
    return navigateTo(nuxtApp.$i18nPath("/"));
  }
});
